<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Drivers/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">#</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="brand" class="col-sm-2 col-form-label">逆變器廠牌</label>
							<div class="col-sm-10">
								<input v-model="model.brand"
									type="text"
									class="form-control"
									id="brand"
									name="brand"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="typename" class="col-sm-2 col-form-label">型號</label>
							<div class="col-sm-10">
								<input v-model="model.typename"
									type="text"
									class="form-control"
									id="typename"
									name="typename"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="drivercode" class="col-sm-2 col-form-label">驅動代碼</label>
							<div class="col-sm-10">
								<input v-model="model.drivercode"
									type="text"
									class="form-control"
									id="drivercode"
									name="drivercode"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note"
									type="text"
									class="form-control"
									id="note"
									name="note"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getDrivers, postDrivers } from '@/api/Drivers.js'

	export default {
		name: 'DriversEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					id: '',
					brand: '',
					typename: '',
					drivercode: '',
					note: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getDrivers(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postDrivers(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>